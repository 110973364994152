<template>
<div class="container-fluid">
	<div class="row position-relative align-items-stretch">
		<div class="position-absolute d-flex align-items-center justify-content-center p-0 p-md-3" style="top: 0px; left: 0px; right: 0px; bottom: 0px; z-index: 1;">
			<img src="@/assets/image/icon_container.webp" class="img-fluid isotipo" style="height: 120px;">
		</div>
		<div class="col-6 bg-credito text-center p-md-5 px-3 py-md-0 py-3">
			<div class="h-100 d-flex flex-column ">
				<h5 style="line-height: 1.8; z-index: 2;" class="position-relative line-height-md-1">
				Accede al <strong>financiamiento</strong> o <strong>crédito</strong> <br>
				que tanto has buscado.
				</h5>
				<router-link :to="{name:'precalifique.index'}" class="btn btn-primary rounded-pill font-weight-bold px-3 px-md-5 position-relative mt-auto" style="z-index: 2;">
					¡Solicita tu préstamo!
				</router-link>
			</div>
		</div>
		<div class="col-6 bg-inversion text-center p-md-5 px-3 py-md-0 py-3">
			<div class="h-100 d-flex flex-column">
				<h5 style="line-height: 1.8; z-index: 2;" class="position-relative line-height-md-1">
				Accede a opciones más rentables y
				<br>seguras de <strong>inversión</strong>.
				</h5>
				<router-link :to="{name:'opciones-inversion.index'}" class="btn btn-primary rounded-pill font-weight-bold px-3 px-md-5 position-relative mt-auto" style="z-index: 2;">
					¡Empieza a Invertir!
				</router-link>
			</div>
		</div>
	</div>
	<div class="row justify-content-center">
		<div class="col-md-7 text-justify p-4" style="font-size: 1.2rem">
			<h2 class="text-center font-weight-bold mb-3">¿Quiénes somos?</h2>
			<br>
			Clubcashin.com fue fundado en 2018 como <strong>la primera plataforma digital de créditos</strong> entre
			personas (P2P) de Guatemala. Desde entonces hemos facilitado millones de quetzales en
			créditos a cientos de personas. Sus fundadores cuentan con más de 25 años de experiencia
			en el sector financiero y tienen la certeza de que el futuro de los servicios financieros será
			100% digital.
			<br>
			<br>
			Clubcashin.com crea conexiones inteligentes, entre quien busca acceso a financiamiento o
			un crédito, con quien busca opciones de inversión más rentables y al conectarse se benefician
			mutuamente en un entorno seguro, confiable y confidencial para que las operaciones sean
			realizadas dentro del marco de la ley.
		</div>
	</div>
	<div class="row bg-secondary">
		<div class="col-md-12 p-3">
			<h2 class="text-center font-weight-bold">Valores</h2>
		</div>
	</div>
	<div class="row justify-content-center">
		<div class="col-md-12 position-relative">
			<img src="@/assets/image/valores.webp" alt="valores" class="position-absolute" style="left: 0px; right: 0px; top:0px; bottom: 0px; width: 100%; height: 100%; object-fit: cover; object-position: center;">
			<div class="row align-items-center h-100 position-relative justify-content-center" style="background-color: rgba(0,0,0,0.31)">
				<div class="col-md-8 px-2 py-2">
					<div class="row align-items-stretch">
						<div class="col-md-4 col-6 mb-3 text-center">
							<img src="@/assets/image/etica.webp" class="img-fluid pt-5 px-5 pb-3 h-valores" alt="Ética">
							<h6 class="text-white text-weight-bold mt-auto">Ética</h6>
						</div>
						<div class="col-md-4 col-6 mb-3 text-center">
							<img src="@/assets/image/trabajo_equipo.webp" class="img-fluid pt-5 px-5 pb-3 h-valores" alt="Trabajo en equipo">
							<h6 class="text-white text-weight-bold mt-auto">Trabajo en equipo</h6>
						</div>
						<div class="col-md-4 col-6 mb-3 text-center">
							<img src="@/assets/image/responsabilidad.webp" class="img-fluid pt-5 px-5 pb-3 h-valores" alt="Responsabilidad">
							<h6 class="text-white text-weight-bold mt-auto">Responsabilidad</h6>
						</div>
						<div class="col-md-4 col-6 mb-3 text-center">
							<img src="@/assets/image/orientacion_servicio.webp" class="img-fluid pt-5 px-5 pb-3 h-valores" alt="Ética">
							<h6 class="text-white text-weight-bold mt-auto">Orientación al servicio</h6>
						</div>
						<div class="col-md-4 col-6 mb-3 text-center">
							<img src="@/assets/image/honestidad.webp" class="img-fluid pt-5 px-5 pb-3 h-valores" alt="Trabajo en equipo">
							<h6 class="text-white text-weight-bold mt-auto">Honestidad</h6>
						</div>
						<div class="col-md-4 col-6 mb-3 text-center">
							<img src="@/assets/image/excelencia.webp" class="img-fluid pt-5 px-5 pb-3 h-valores" alt="Responsabilidad">
							<h6 class="text-white text-weight-bold mt-auto">Excelencia</h6>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row justify-content-center pt-3 px-3 pb-5 bg-secondary-light">
		<div class="col-md-9 col-lg-7">
			<h2 class="text-center font-weight-bold">Misión</h2>
			<br>
			<div class="border rounded-pill text-center p-3 border-radius-force">
				Generamos <strong>oportunidades de financiamiento e inversión</strong> ágiles y eficientes por medio de la tecnología y la innovación.
			</div>
		</div>
	</div>
	<div class="row justify-content-center">
		<div class="col-md-12 px-0">
			<img src="@/assets/image/logros.webp" alt="valores" class="position-absolute" style="left: 0px; right: 0px; top:0px; bottom: 0px; width: 100%; height: 100%; object-fit: cover; object-position: center;">
			<div class="position-relative p-3 p-md-5 text-center" style="background-color: rgba(0,0,0,0.31);">
				<h1 class="my-5 text-white font-weight-bold">Logros</h1>
				<img src="@/assets/image/flechas_azules.webp" class="img-fluid" style="height: 50px;" alt="Flechas azules">
				<div class="row align-items-center h-100 position-relative justify-content-center text-white">
					<div class="col-md-8 px-2 py-5">
						<div class="row align-items-stretch">
							<div class="col-md-4 col-6 mb-3 text-center order-0">
								<h1 class="text-secondary font-weight-bold">cientos</h1>
								<p>de <strong>créditos</strong> <br> otorgados en el país</p>
							</div>
							<div class="col-md-4 mb-3 text-center order-5 order-md-1">
								<img src="@/assets/image/logros-icon.webp" class="img-fluid p-3" style="height: 150px;" alt="Logros">
							</div>
							<div class="col-md-4 col-6 mb-3 text-center order-1 order-md-2">
								<h1 class="text-secondary font-weight-bold">millones</h1>
								<p>
									<strong>de quetzales</strong> <br> otorgados en créditos
								</p>
							</div>
							<div class="col-md-4 col-6 mb-3 text-center order-2 order-md-3">
								<div class="bg-white mb-5 mx-auto" style="height: 10px; width: 25px;"></div>
								<h1 class="text-secondary font-weight-bold">cientos</h1>
								<p>
									de <strong>usuarios</strong> <br> registrados
								</p>
							</div>
							<div class="col-md-4 mb-3 text-center order-6 order-md-4">
								<h1 class="text-secondary font-weight-bold">1ra.</h1>
								<p>
									Plataforma de <br> créditos <strong>P2P</strong> en <br> Guatemala
								</p>
							</div>
							<div class="col-md-4 mb-3 col-6 text-center order-3 order-md-5">
								<div class="bg-white mb-5 mx-auto" style="height: 10px; width: 25px;"></div>
								<p>
									<strong>Rendimiento</strong> <br> promedio anual
								</p>
								<h1 class="text-secondary font-weight-bold">18%</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>