<template>
<div class="py-3 h-100 position-relative">
	<div class="w-50 position-absolute mh100 bg-credito" style="bottom: 0px; top:0px; left: 0px; min-height: 100%;"></div>
	<div class="w-50 position-absolute mh100 bg-inversion" style="bottom: 0px; top:0px; right: 0px; min-height: 100%;"></div>
	<div class="position-relative mx-auto h-100">
		<div class="row justify-content-center align-items-center h-100 mx-0" style="min-height: calc(100vh - 164px)">
			<div class="col-lg-6 my-5">
				<div class="card bg-light" style="border-radius: 1rem;">
					<div class="card-body p-3 p-md-4">
						<form name="userForm" v-on:submit.prevent="sendContact()" ref="userForm" class="needs-validation was-validated" novalidate>
							<div class="row justify-content-center text-center">
								<div class="col-md-8 mb-3 py-3">
									<img src="@/assets/image/logo_dark.webp" class="img-fluid w-50">
								</div>
							</div>
							<div class="row">
								<div class="col-md-6 mb-3">
									<ul class="list-unstyled">
										<li class="media mb-3">
											<div class="media-body text-center font-weight-bold">
												Horarios de atención: 8am - 5pm
											</div>
										</li>
										<li class="media mb-3">
											<div class="mr-3">
												<img src="@/assets/image/phone.webp" style="width: 18px; height: auto;" alt="Teléfono">
											</div>
											<div class="media-body text-left">
												<a href="tel:2234-1333">2234-1333</a>
											</div>
										</li>
										<li class="media mb-3">
											<div class="mr-3">
												<img src="@/assets/image/whatsapp.webp" style="width: 18px; height: auto;" alt="WhatsApp">
											</div>
											<div class="media-body text-left">
												<a href="https://api.whatsapp.com/send?phone=+50222341368&text=Quiero%20m%C3%A1s%20informaci%C3%B3n" target="_blank">2234-1368</a>
											</div>
										</li>
										<li class="media mb-3">
											<div class="mr-3">
												<img src="@/assets/image/correo.webp" style="width: 18px; height: auto;" alt="Correo">
											</div>
											<div class="media-body text-left">
												<a href="mailto:info@clubcashin.com?subject=Quiero%20m%C3%A1s%20informaci%C3%B3n&body=Hola%2C%20Quiero%20m%C3%A1s%20informaci%C3%B3n.">info@clubcashin.com</a>
											</div>
										</li>
										<li class="media mb-3">
											<div class="mr-3">
												<img src="@/assets/image/ubicacion.webp" style="width: 18px; height: auto;" alt="Ubicación">
											</div>
											<div class="media-body text-left">
												<a href="https://goo.gl/maps/gcTBPC1mfs1WQ5dp9" target="_blank">
													3a avenida “A” 13-78, Colonia Lomas de Pamplona zona 13, Guatemala.
												</a>
											</div>
										</li>
									</ul>
									<div class="rounded overflow-hidden shadow">

										<iframe 
						                 	src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.9976211456114!2d-90.53698358474!3d14.599211281023626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a1a13ede014d%3A0xcc9c190a50d9f749!2sClub%20Cash%20In!5e0!3m2!1ses!2sgt!4v1647408300794!5m2!1ses!2sgt" 
						                 	height="350"  
						                 	loading="lazy"
						                 	frameborder="0" 
							                scrolling="no" 
							                marginheight="0" 
							                marginwidth="0" 
							                class="w-100"></iframe>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="text-center">
										<label>Envíanos tus dudas o comentarios:</label>
									</div>
									<div class="mb-2">
										<input type="text" class="form-control" maxlength="150" v-model="form.nombres" placeholder="Nombres" required>
									</div>
									<div class="mb-2">
										<input type="tel" class="form-control" required v-model="form.telefono" placeholder="Teléfono">
									</div>
									<div class="mb-2">
										<input type="email" class="form-control" v-model="form.mail" required placeholder="Correo">
									</div>
									<div class="mb-2">
										<input type="text" class="form-control" required v-model="form.asunto" placeholder="Asunto">
									</div>
									<div class="mb-2">
										<textarea v-model="form.mensaje" class="form-control" maxlength="1000" required placeholder="Descripción" rows="4"></textarea>
									</div>
									<div class="mb-2 text-right">
										<button type="submit" class="btn btn-secondary rounded-pill font-weight-bold px-4 text-primary" :disabled="loadingContacto || !validateForm">
											<BtnLoading v-if="loadingContacto" />
											<span v-else>
												Enviar
											</span>
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	export default{
		components: {
			BtnLoading
		},
		data(){
			return {
				validateForm: false,
				loadingContacto: false,
				form: {
					nombres: '',
					telefono: '',
					mail: '',
					asunto: '',
					mensaje: '',
				}
			}
		},
		mounted(){
			this.setDefault()
		},
		methods: {
			setDefault(){
				if (this.isLoggedIn) {
					this.form.nombres = (this.currentUser.nombre || '') + ' ' + (this.currentUser.apellido || '')
				}else{
					this.form.nombres = ''
				}
				this.form.telefono = this.currentUser.telefono || ''
				if (this.form.telefono == 'null') {
					this.form.telefono = ''
				}
				this.form.mail = this.currentUser.mail || ''
			},
			resetFields(){
				this.form.asunto = ''
				this.form.mensaje = ''
				this.validateForm = false
				this.setDefault()
			},
			sendContact(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingContacto = true
				this.$store.dispatch('addContacto', this.form).then(response => {
					window.toastr.info("Gracias por escribirnos, pronto nos comunicaremos contigo", "Aviso")
					this.resetFields()
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingContacto = false
				})
			},
			checkValidity(){
				if (this.$refs.userForm){
					this.validateForm = this.$refs.userForm.checkValidity()
				}else{
					this.validateForm = false
				}
			}
		},
		computed: {
			isLoggedIn(){
				return this.$store.getters.isLoggedIn 
			},
			currentUser(){
				return this.$store.getters.currentUser
			}
		},
		watch:{
			'currentUser':{
				handler(val){
					this.setDefault()
					return val
				},
				deep: true
			},
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		},
	}
</script>